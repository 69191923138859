import { useEffect, useState, useMemo } from 'react'
import {
  SortByInterface,
  SORT_DIRECTION,
  RowInterface,
  CellTypes,
  FilterType,
  ColumnCellInterface,
  FilterByInterface,
} from '@src/interfaces/data'
import {
  ReportColumnsTableInterface,
  ReportHighlightInterface,
  ReportFilterInterface,
  QueryInterface,
  QueryRunResultInterface,
} from '@src/interfaces/dataAnalytics'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { getReportColumnValues, getQueryRunResult } from '@src/api/dataAnalytics'
import {
  valueColorMap,
  rowColorMap,
} from '@src/pages/Forms/DataAnalyticsReportForm/common'
import { selectorKeys } from '@src/constants/api'
import { getColumnAttributes } from '@src/pages/Forms/DataAnalyticsReportViewForm/utils'
import { useTable } from '@components/Table/hooks'

export interface ReorderSettingsInterface {
  hidden: ReportColumnsTableInterface[]
  visible: ReportColumnsTableInterface[]
}

export interface FilterSettingsInterface {
  hidden: ReportFilterInterface[]
  visible: ReportFilterInterface[]
}

export interface SortSettingsInterface {
  hidden: SortByInterface[]
  visible: SortByInterface[]
}

export const orderingToSort = (ordering: string[]): SortByInterface[] => {
  return ordering.map(val => {
    const desc = val[0] === '-'

    if (desc) {
      return {
        sortBy: val.slice(1),
        direction: SORT_DIRECTION.DESC,
      }
    }

    return {
      sortBy: val,
      direction: SORT_DIRECTION.ASC,
    }
  })
}

const sortToOrdering = (sort: SortByInterface[]): string[] => {
  return sort.map(val =>
    val.direction === SORT_DIRECTION.DESC ? `-${val.sortBy}` : val.sortBy,
  )
}

const shouldHighlight = (highlight: ReportHighlightInterface, value: unknown) => {
  if (
    !highlight.condition.condition_type ||
    highlight.condition.condition_type.id === 'eq'
  ) {
    return String(value) === highlight.condition.value
  }

  if (typeof value !== 'number') {
    return false
  }

  if (highlight.condition.condition_type.id === 'lt') {
    return value < Number(highlight.condition.value)
  }

  if (highlight.condition.condition_type.id === 'gt') {
    return value > Number(highlight.condition.value)
  }

  return false
}

export const getTableRows = (
  queryId: number,
  columns: ReportColumnsTableInterface[],
  filters?: ReportFilterInterface[],
  highlighting?: ReportHighlightInterface[] | null,
  runId?: number,
) => {
  const cells = columns.map(column => {
    const columnAttributes = getColumnAttributes(column, filters)
    return {
      type: columnAttributes?.type || CellTypes.text,
      idPoint: column.name,
      dataPoint: column.name,
      sortKey: column.name,
      filterKey: columnAttributes?.filterKey || null,
      filterType: columnAttributes?.filterType || undefined,
      selectorsKey: () =>
        columnAttributes?.filterType === FilterType.selector && runId
          ? getReportColumnValues(queryId, runId, column.name)
          : selectorKeys.none,
      title: column.alias || column.name,
      width: 300,
      colors: data => {
        const color = highlighting?.find(
          highlight =>
            highlight.target.id === 'value' &&
            highlight.condition.key === column.name &&
            shouldHighlight(highlight, data[highlight.condition.key]),
        )?.color
        return color ? valueColorMap[color] : undefined
      },
      insert:
        columnAttributes?.type === CellTypes.insert
          ? ({ data }) => data?.[column.name] || '-'
          : undefined,
    } as ColumnCellInterface<any>
  })

  return {
    highlight: (data: any) => {
      const color = highlighting?.find(
        highlight =>
          highlight.target.id === 'row' &&
          shouldHighlight(highlight, data[highlight.condition.key]),
      )?.color
      return color ? rowColorMap[color] : ''
    },
    cells,
  }
}

export const getFilters = (
  columns: ReportColumnsTableInterface[],
  filterBy: ReportFilterInterface[],
) => {
  return columns.reduce((acc, column) => {
    const filter = filterBy?.find(f => f.column_name === column.name)
    if (!filter) {
      return acc
    }
    const attributes = getColumnAttributes(column, undefined, filter.column_name)
    if (filter.default_value && attributes?.filterKey) {
      acc.push({
        columnName: attributes.filterKey,
        filters: JSON.parse(filter.default_value) || [],
      })
    }
    return acc
  }, [] as FilterByInterface[])
}

export const useTableSettings = (
  runId?: number,
  queryId?: number,
  enabled = true,
  disableQuery = false,
) => {
  const { values } = useLapeContext<QueryInterface>()

  // older queries don't have these migrations on BE, so we set defaults on FE
  if (!values.output_format) {
    values.output_format = {
      columns:
        values.columns?.map(col => ({
          name: col.name,
          type: col.type,
          alias: null,
        })) || [],
      ordering: undefined,
      filters: undefined,
      highlighting: null,
    }
  }

  const allVisibleColumnsReorder: ReorderSettingsInterface = useMemo(
    () => ({
      visible:
        values.columns?.map(column => ({
          name: column.name,
          type: column.type,
          alias: null,
        })) || [],
      hidden: [],
    }),
    [],
  )

  const initialReorderSettings = useMemo(
    () =>
      values.output_format?.columns
        ? {
            visible: values.output_format.columns,
            hidden: allVisibleColumnsReorder.visible.filter(
              column =>
                !values.output_format?.columns.find(vCol => vCol.name === column.name),
            ),
          }
        : allVisibleColumnsReorder,
    [],
  )

  let initialSortSettings: SortSettingsInterface = useMemo(() => {
    if (values.output_format?.ordering) {
      const visibleColumnsSort = orderingToSort(values.output_format.ordering).filter(
        vCol =>
          initialReorderSettings.visible.find(column => column.name === vCol.sortBy),
      )

      return {
        visible: visibleColumnsSort,
        hidden: initialReorderSettings.visible
          .filter(column => !visibleColumnsSort.find(vCol => vCol.sortBy === column.name))
          .map(col => ({
            sortBy: col.name,
            direction: SORT_DIRECTION.DESC,
          })),
      }
    }

    return {
      visible: [],
      hidden: initialReorderSettings.visible.map(column => ({
        sortBy: column.name,
        direction: SORT_DIRECTION.DESC,
      })),
    }
  }, [])

  let initialFilterSettings: FilterSettingsInterface = useMemo(
    () => ({
      visible: values.output_format?.filters || [],
      hidden: initialReorderSettings.visible
        .filter(
          vCol =>
            !values.output_format?.filters?.find(
              column => column.column_name === vCol.name,
            ),
        )
        .map(col => ({
          column_name: col.name,
          default_value: null,
        })),
    }),
    [],
  )

  const [reorderSettings, setReorderSettings] =
    useState<ReorderSettingsInterface>(initialReorderSettings)
  const [filterSettings, setFilterSettings] =
    useState<FilterSettingsInterface>(initialFilterSettings)
  const [sortSettings, setSortSettings] =
    useState<SortSettingsInterface>(initialSortSettings)
  const [highlightSettings, setHighlightSettings] = useState<
    ReportHighlightInterface[] | null
  >(values.output_format?.highlighting || null)
  const [tableRows, setTableRows] = useState<RowInterface<QueryRunResultInterface>>({
    cells: [],
  })
  const initialFilterBy = useMemo(
    () =>
      values.output_format?.columns
        ? getFilters(values.output_format.columns, filterSettings.visible)
        : undefined,
    [],
  )

  const table = useTable(
    {
      getItems: getQueryRunResult(runId!, queryId!),
    },
    initialFilterBy,
    initialSortSettings.visible,
    { disable: !runId || !queryId || !enabled, disableQuery },
  )

  useEffect(() => {
    setSortSettings(current => {
      const visibleColumns = current.visible.filter(vCol =>
        reorderSettings.visible.find(column => column.name === vCol.sortBy),
      )

      return {
        visible: visibleColumns,
        hidden: reorderSettings.visible
          .filter(column => !visibleColumns.find(vCol => vCol.sortBy === column.name))
          .map(col => ({
            sortBy: col.name,
            direction: SORT_DIRECTION.DESC,
          })),
      }
    })
  }, [reorderSettings.visible.length])

  useEffect(() => {
    setFilterSettings(current => {
      const visibleColumns = current.visible.filter(vCol =>
        reorderSettings.visible.find(column => column.name === vCol.column_name),
      )

      return {
        visible: visibleColumns,
        hidden: reorderSettings.visible
          .filter(
            column => !visibleColumns.find(vCol => vCol.column_name === column.name),
          )
          .map(col => ({
            column_name: col.name,
            default_value: null,
          })),
      }
    })
  }, [filterSettings.visible.length, reorderSettings.visible.length])

  useEffect(() => {
    if (table.columns.length === 0) {
      return
    }
    // we assume this is run only the first time and no settings were saved previously
    if (reorderSettings.visible.length === 0 && reorderSettings.hidden.length === 0) {
      setReorderSettings(prev => ({
        ...prev,
        visible: table.columns.map(c => ({ ...c, alias: null })),
      }))
      return
    }

    const newColumns = table.columns
      .filter(
        col =>
          !(
            reorderSettings.visible.some(vCol => col.name === vCol.name) ||
            reorderSettings.hidden.some(hCol => col.name === hCol.name)
          ),
      )
      .map(c => ({ ...c, alias: null }))

    const visibleColumns = reorderSettings.visible
      .filter(vCol => table.columns.some(col => col.name === vCol.name))
      .concat(newColumns)

    setReorderSettings(prev => ({
      visible: visibleColumns,
      hidden: prev.hidden.filter(hCol =>
        table.columns.some(col => col.name === hCol.name),
      ),
    }))
  }, [table.columns])

  useEffect(() => {
    table.resetFiltersAndSorting(
      getFilters(reorderSettings.visible, filterSettings.visible),
      sortSettings.visible,
    )
  }, [filterSettings.visible, sortSettings.visible])

  useEffect(() => {
    values.output_format!.columns = [...reorderSettings.visible]

    const rows = getTableRows(
      queryId || values.id,
      reorderSettings.visible,
      filterSettings.visible,
      highlightSettings,
      runId,
    )

    setTableRows(rows)
  }, [reorderSettings, highlightSettings, filterSettings.visible, queryId, runId])

  useEffect(() => {
    values.output_format!.columns = [...reorderSettings.visible]
  }, [reorderSettings])

  useEffect(() => {
    values.output_format!.ordering = sortToOrdering(sortSettings.visible)
  }, [sortSettings])

  useEffect(() => {
    values.output_format!.filters = filterSettings.visible
  }, [filterSettings])

  useEffect(() => {
    values.output_format!.highlighting = highlightSettings
  }, [highlightSettings])

  return {
    reorderSettings,
    setReorderSettings,
    sortSettings,
    setSortSettings,
    filterSettings,
    setFilterSettings,
    highlightSettings,
    setHighlightSettings,
    table,
    tableRows,
  }
}
