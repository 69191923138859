import React from 'react'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'

import {
  Box,
  Circle,
  Color,
  Flex,
  HStack,
  IconButton,
  Tag,
  Text,
  Token,
} from '@revolut/ui-kit'
import { ArrowThinDown, ArrowThinUp, EyeHide, InfoOutline, Minus } from '@revolut/icons'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import {
  EngagementResultInterface,
  EngagementResultsComment,
} from '@src/interfaces/engagement'
import { DistributionChart } from '@src/apps/People/Engagement/Results/components/DistributionChart'
import {
  benchmarkToColor,
  scoreToColor,
  trendValueToColor,
} from '@src/apps/People/Engagement/Results/helpers'
import { InsertCommentsNode } from '@src/apps/People/Engagement/Results/components/InsertCommentsNode'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { isNumber } from 'lodash'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '../routes'

const TooltipContainer = styled.div`
  background-color: ${Token.color.foreground};
  border-radius: ${Token.radius.r16};
  padding: ${Token.space.s8};
  color: ${Token.color.background};
  opacity: 0.9;
  z-index: 999;
  width: max-content;
  max-width: 300px;
`

const HideDataIcon = (
  <Flex width="100%" justifyContent="flex-start">
    <Tooltip
      placement="top"
      body={
        <Box padding="s-8" width={210}>
          <Text color={Token.color.background}>
            This data is hidden to protect the anonymity of the respondents
          </Text>
        </Box>
      }
    >
      <EyeHide size={14} color={Token.color.greyTone50} />
    </Tooltip>
  </Flex>
)

const trendValueToIcon = (color: Color, value: number | null) => {
  if (value === null || value === undefined) {
    return null
  }
  if (value > 0) {
    return <ArrowThinUp color={color} size={16} />
  }
  if (value < 0) {
    return <ArrowThinDown color={color} size={16} />
  }
  return <Minus color={color} size={12} />
}

const insertTrendNode = (value: number | null) => {
  const color = trendValueToColor(value)
  const icon = trendValueToIcon(color, value)
  let formattedValue

  if (typeof value === 'number') {
    formattedValue = +value.toFixed(2)
  }

  return (
    <HStack space="s-2" align="center">
      <Text color={color}>{formattedValue ?? 'N/A'}</Text>
      {icon}
    </HStack>
  )
}

export const engagementResultsDriverNameColumn: ColumnInterface<EngagementResultInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'driver.id',
    dataPoint: 'driver.name',
    sortKey: 'driver__name',
    filterKey: 'driver__id',
    selectorsKey: selectorKeys.engagement_drivers,
    title: 'Driver',
    insert: ({ data }) => {
      return (
        <HStack space="s-8" align="center">
          <Text>{data.driver?.name || '-'}</Text>
          {data.driver?.description && (
            <Tooltip text={data.driver.description} placement="top">
              <InfoOutline color={Token.color.greyTone20} size={15} />
            </Tooltip>
          )}
        </HStack>
      )
    },
  }

export const engagementResultsCategoryNameColumn: ColumnInterface<EngagementResultInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'driver.id',
    dataPoint: 'driver.name',
    sortKey: 'driver__name',
    filterKey: 'driver__id',
    selectorsKey: selectorKeys.engagement_drivers,
    title: 'Category',
    insert: ({ data }) => {
      return (
        <HStack space="s-8" align="center">
          <Text>{data.driver?.name || '-'}</Text>
          {data.driver?.description && (
            <Tooltip text={data.driver.description} placement="top">
              <InfoOutline color={Token.color.greyTone20} size={15} />
            </Tooltip>
          )}
        </HStack>
      )
    },
  }

export const engagementResultsQuestionNameColumn: ColumnInterface<EngagementResultInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'question.id',
    dataPoint: 'question.question_text',
    sortKey: 'question__title',
    filterKey: 'question__id',
    selectorsKey: selectorKeys.engagement_question_texts,
    title: 'Question',
    insert: ({ data }) => (
      <Box py="s-12">
        <Text whiteSpace="pre-wrap">{data.question?.question_text || '-'}</Text>
      </Box>
    ),
    notHoverable: true,
  }

export const engagementResultsQuestionTypeColumn: ColumnInterface<EngagementResultInterface> =
  {
    type: CellTypes.text,
    idPoint: 'question.type.id',
    dataPoint: 'question.type.name',
    sortKey: 'type',
    filterKey: 'type',
    selectorsKey: selectorKeys.engagement_question_types,
    title: 'Question type',
  }

export const engagementResultsQuestionCategoryNameColumn: ColumnInterface<EngagementResultInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'driver.id',
    dataPoint: 'driver.name',
    sortKey: 'question__driver__name',
    filterKey: 'question__driver__id',
    selectorsKey: selectorKeys.engagement_drivers,
    title: 'Category',
    insert: ({ data }) => {
      return (
        <HStack space="s-8" align="center">
          <Text>{data.driver?.name || '-'}</Text>
          {data.driver?.description && (
            <Tooltip
              placement="top"
              body={
                <TooltipContainer>
                  <Text>{data.driver.description}</Text>
                </TooltipContainer>
              }
            >
              <InfoOutline color={Token.color.greyTone20} size={15} />
            </Tooltip>
          )}
        </HStack>
      )
    },
  }

export const engagementResultsQuestionDriverNameColumn: ColumnInterface<EngagementResultInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'driver.id',
    dataPoint: 'driver.name',
    sortKey: 'question__driver__name',
    filterKey: 'question__driver__id',
    selectorsKey: selectorKeys.engagement_drivers,
    title: 'Driver',
    insert: ({ data }) => {
      return (
        <HStack space="s-8" align="center">
          <Text>{data.driver?.name || '-'}</Text>
          {data.driver?.description && (
            <Tooltip
              placement="top"
              body={
                <TooltipContainer>
                  <Text>{data.driver.description}</Text>
                </TooltipContainer>
              }
            >
              <InfoOutline color={Token.color.greyTone20} size={15} />
            </Tooltip>
          )}
        </HStack>
      )
    },
  }

export const engagementResultsAverageScoreColumn: ColumnInterface<EngagementResultInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'average_score',
    dataPoint: 'average_score',
    sortKey: 'average_score',
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Score',
    insert: ({ data }) => {
      if (data.question?.type.id === 'open_ended' || data.average_score === null) {
        return <Text>-</Text>
      }
      if (!data.can_show_details) {
        return HideDataIcon
      }
      return data.average_score ? +data.average_score.toFixed(2) : '-'
    },
    headerTooltip: (
      <Box color={Token.color.background} p="s-8" minWidth={250}>
        The average score is equal to the average of all answers for each particular
        question or driver, it ranges from 1 to 5
      </Box>
    ),
  }

export const engagementResultsNpsScoreColumn: ColumnInterface<EngagementResultInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'nps_score',
    dataPoint: 'nps_score',
    sortKey: 'nps_score',
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Score',
    insert: ({ data }) => {
      if (data.question?.type.id === 'open_ended' || data.nps_score === null) {
        return <Text>-</Text>
      }
      if (!data.can_show_details) {
        return HideDataIcon
      }
      return <Text>{data.nps_score}</Text>
    },
    headerTooltip: (
      <Box color={Color.BACKGROUND} p="s-8" minWidth={250}>
        The NPS (net promoter score) is equal to the percentage of promoters minus the
        percentage of detractors, it ranges from -100 to +100
      </Box>
    ),
  }

export const engagementResultsTrendAverageScoreColumn: ColumnInterface<EngagementResultInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'trend_average_score',
    dataPoint: 'trend_average_score',
    sortKey: 'trend_average_score',
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Trend',
    insert: ({ data }) => insertTrendNode(data.trend_average_score),
  }

export const engagementResultsBenchmarkColumn: ColumnInterface<EngagementResultInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'benchmark.id',
    dataPoint: 'benchmark.name',
    sortKey: 'benchmark',
    filterKey: 'benchmark',
    selectorsKey: selectorKeys.culture_percentiles,
    title: 'Benchmark',
    colors: data => benchmarkToColor(data.benchmark),
    insert: ({ data }) => {
      if (!data.can_show_details) {
        return HideDataIcon
      }
      return <Text>{data.benchmark?.name || '-'}</Text>
    },
  }

export const engagementResultsTrendNpsScoreColumn: ColumnInterface<EngagementResultInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'trend_nps_score',
    dataPoint: 'trend_nps_score',
    sortKey: 'trend_nps_score',
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Trend',
    insert: ({ data }) => {
      if (data.question?.type.id === 'open_ended' || data.trend_nps_score === null) {
        return <Text>N/A</Text>
      }
      if (!data.can_show_details) {
        return HideDataIcon
      }
      return insertTrendNode(data.trend_nps_score)
    },
  }

export const engagementResultsAverageDistributionColumn: ColumnInterface<EngagementResultInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'average_distribution',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Distribution',
    insert: ({ data }) => {
      if (
        data.scores_distribution === null &&
        data.open_ended_distribution === null &&
        data.detractors === null &&
        data.passives === null &&
        data.promoters === null &&
        data.unanswered === null
      ) {
        return <Text>-</Text>
      }
      if (!data.can_show_details) {
        return HideDataIcon
      }
      if (
        data.question?.type.id === 'open_ended' &&
        isEmpty(data.open_ended_distribution)
      ) {
        return '-'
      }
      return <DistributionChart isAverage data={data} />
    },
  }

export const engagementResultsNpsDistributionColumn: ColumnInterface<EngagementResultInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'nps_distribution',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Distribution',
    insert: ({ data }) => {
      if (
        data.scores_distribution === null &&
        data.open_ended_distribution === null &&
        data.detractors === null &&
        data.passives === null &&
        data.promoters === null &&
        data.unanswered === null
      ) {
        return <Text>-</Text>
      }
      if (!data.can_show_details) {
        return HideDataIcon
      }
      if (
        data.question?.type.id === 'open_ended' &&
        isEmpty(data.open_ended_distribution)
      ) {
        return '-'
      }
      return <DistributionChart data={data} />
    },
  }

export const engagementDriverActionsColumn = (
  surveyId: number,
): ColumnInterface<EngagementResultInterface> => ({
  type: CellTypes.insert,
  idPoint: 'driver_actions',
  dataPoint: 'driver_actions',
  title: '',
  filterKey: null,
  sortKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (
      data.number_of_answers_with_comment === null ||
      (isNumber(data.number_of_answers_with_comment) &&
        data.number_of_answers_with_comment > 0)
    ) {
      return (
        <IconButton
          useIcon="Chat"
          size={16}
          onClick={() =>
            navigateReplace(
              pathToUrl(
                ROUTES.PERFORMANCE.ENGAGEMENT.DETAILS_RESULTS.COMMENTS,
                {
                  id: surveyId,
                },
                { driver__id: String(data.driver?.id) },
              ),
            )
          }
        />
      )
    }
    return null
  },
})

export const engagementQuestionsActionsColumn = (
  surveyId: number,
): ColumnInterface<EngagementResultInterface> => ({
  type: CellTypes.insert,
  idPoint: 'driver_actions',
  dataPoint: 'driver_actions',
  title: '',
  filterKey: null,
  sortKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (
      (isNumber(data.number_of_answers_with_comment) &&
        data.number_of_answers_with_comment > 0) ||
      data.question?.type.id === 'open_ended'
    ) {
      return (
        <IconButton
          useIcon="Chat"
          size={16}
          onClick={() =>
            navigateReplace(
              pathToUrl(
                ROUTES.PERFORMANCE.ENGAGEMENT.DETAILS_RESULTS.COMMENTS,
                {
                  id: surveyId,
                },
                {
                  driver__id: String(data.driver?.id),
                  question__id: String(data.question?.id),
                },
              ),
            )
          }
        />
      )
    }
    return null
  },
})

export const engagementResultsAnswersColumn: ColumnInterface<EngagementResultInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'number_of_answers_with_comment',
    dataPoint: 'number_of_answers_with_comment',
    sortKey: 'number_of_answers_with_comment',
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Answers',
    insert: InsertCommentsNode,
  }

export const engagementResultsFeedbackAnswerColumn: ColumnInterface<EngagementResultsComment> =
  {
    type: CellTypes.insert,
    idPoint: 'id',
    dataPoint: 'answer_text',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Answer',
    notHoverable: true,
    insert: ({ data }) => (
      <Text use="div" py="s-12" whiteSpace="pre-wrap">
        {data.answer_text}
      </Text>
    ),
  }

export const engagementResultsFeedbackQuestionColumn: ColumnInterface<EngagementResultsComment> =
  {
    type: CellTypes.text,
    idPoint: 'question.id',
    dataPoint: 'question.question_text',
    sortKey: 'question__question_text',
    filterKey: 'question__id',
    selectorsKey: selectorKeys.engagement_question_texts,
    title: 'Question',
  }

export const engagementCommentAnswerText: ColumnInterface<EngagementResultsComment> = {
  type: CellTypes.text,
  idPoint: 'answer_text',
  dataPoint: 'answer_text',
  sortKey: 'answer_text',
  filterKey: 'answer_text',
  filterType: FilterType.text,
  selectorsKey: selectorKeys.none,
  title: 'Comment',
}

export const engagementResultsFeedbackCategoryColumn: ColumnInterface<EngagementResultsComment> =
  {
    type: CellTypes.text,
    idPoint: 'driver.name',
    dataPoint: 'driver.name',
    sortKey: 'driver__name',
    filterKey: 'driver__id',
    selectorsKey: selectorKeys.engagement_drivers,
    title: 'Category',
  }

export const engagementResultsFeedbackCreationDateColumn: ColumnInterface<EngagementResultsComment> =
  {
    type: CellTypes.date,
    idPoint: 'creation_date_time',
    dataPoint: 'creation_date_time',
    sortKey: 'creation_date_time',
    filterKey: 'creation_date_time',
    selectorsKey: selectorKeys.none,
    filterType: FilterType.date,
    title: 'Date',
  }

export const engagementResultsFeedbackAnswerScoreColumn: ColumnInterface<EngagementResultsComment> =
  {
    type: CellTypes.text,
    idPoint: 'answer_score',
    dataPoint: 'answer_score',
    sortKey: 'answer_score',
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Answer',
  }

export const engagementResultsFeedbackScoreColumn: ColumnInterface<EngagementResultsComment> =
  {
    type: CellTypes.insert,
    idPoint: 'id',
    dataPoint: 'score',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Category / Score',
    insert: ({ data }) => {
      if (typeof data.answer_score === 'number') {
        return (
          <Circle size={16} variant="outlined" color={scoreToColor(data.answer_score)}>
            <Text
              fontSize="x-small"
              fontWeight={500}
              color={scoreToColor(data.answer_score)}
            >
              {data.answer_score}
            </Text>
          </Circle>
        )
      }
      if (data.categories?.names?.length) {
        return (
          <HStack space="s-4">
            {data.categories.names.map(name => (
              <Tag key={name} variant="outlined">
                {name}
              </Tag>
            ))}
          </HStack>
        )
      }
      return '-'
    },
  }
